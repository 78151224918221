import { Injectable } from '@angular/core';
import { FeatureFlag } from 'app/shared/enums';
import { environment } from 'environments/environment';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagValue } from 'launchdarkly-js-client-sdk';

Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  client: LaunchDarkly.LDClient;

  initialize(): Promise<void> {
    const user = JSON.parse(localStorage.getItem('current_user'));

    if (!user) return Promise.resolve();

    const context: LaunchDarkly.LDContext = {
      kind: 'user',
      key: `${user.first_name}-${user.last_name}-${user.prsn_id}`,
    };
    this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, context);
    return this.client.waitUntilReady();
  }

  getFlag(flagKey: FeatureFlag, defaultValue: LDFlagValue = false): boolean {
    return this.client.variation(flagKey, defaultValue);
  }
}
