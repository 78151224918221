import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ReadonlyGuard  {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  canActivate(): boolean {
    if (this.authenticationService.isReadonly) {
      this.router.navigate(['forbidden']);
    }
    return true;
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }
}