import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';


@Injectable()
export class AuthGuard  {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(state);
  }

  private check(state: RouterStateSnapshot) {
    if (this.authenticationService.loggedIn() ) {
      return true;
    } else {
      this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
