import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable()
export class SpinnerService {
  public pendingRequestsCount = 0;
  public spinnerState$ = new BehaviorSubject<boolean>(false);

  addRequest(): void {
    this.pendingRequestsCount = this.pendingRequestsCount + 1;
    this.spinnerState$.next(true);
  }

  removeRequest(): void {
    this.pendingRequestsCount = this.pendingRequestsCount - 1;
    if (this.pendingRequestsCount <= 0) {
      this.pendingRequestsCount = 0;
      this.spinnerState$.next(false);
    }
  }
}

